import { Flex, Heading } from "@radix-ui/themes";
import logoUrl from "./logo.png";
import style from "./style.module.css";

export default function SidebarLogo() {
  return (
    <Flex p="3" align="center">
      <img src={logoUrl} alt="Reel logo" className={style.Logo} />
      <Heading size="4">Reel</Heading>
    </Flex>
  );
}
